import Link from '@/components/link'
// import { useDownloadStore } from '@/store/download'

import { getThemeTypeImageUrl, getLogoImageUrl } from '@/helper/common'
import styles from './index.module.css'

export function SVGLogo() {
  // const { disposition } = useDownloadStore()
  // const { businessName, imgWebLogo } = disposition || {}

  return (
    <Link href="/" className={styles.scoped}>
      <div className="relative">
        <img src={`${getLogoImageUrl('logo', false, 'png')}`} alt={'newChain Logo'} />
        {/* <img src={`${getThemeTypeImageUrl('vpn/zh-cn2', false, 'svg')}`} alt={'newChain Logo'} /> */}
        {/* <LottiePlayer
          animationData={jsonData}
          loop
          rendererSettings={{
            preserveAspectRatio: 'none meet',
          }}
          style={{ width: '100%', height: '100%', position: 'absolute', left: '0', top: '0' }}
        /> */}
      </div>
      {/* <LazyImage
        // 设置大小防止闪动
        className="logo"
        height={50}
        width={128}
        src={`${oss_svg_image_domain_address}home/${LogoMap(locale)}`}
        alt={'ChainStar Logo'}
        // LOGO 直接显示图片，这里不需要 lazy load
        visibleByDefault
        whetherPlaceholdImg={false}
      /> */}
    </Link>
  )
}
