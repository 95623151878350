import { t } from '@lingui/macro'
import { oss_svg_image_domain_address } from '@/constants/oss'
import { useEffect, useRef, useState } from 'react'
import { baseCommonStore, useCommonStore } from '@/store/common'
import { isCommon, localeIsCnType } from '@/helper/common'
import Icon from '@/components/icon'
import { getDownloadPath } from '@/helper/route/homepage'
import { link } from '@/helper/link'
import { Modal } from '@arco-design/web-react'
import { useUserStore, baseUserStore } from '@/store/user'
import { appItemHandle, equipmentEnum, onOFFemum } from '@/constants/homepage'
import { getSupportColumn, getWebsiteData } from '@/apis/help-center'
import { businessId } from '@/helper/env'
import { useCardInfo } from '@/store/lin'
import classNames from 'classnames'
import { useDownloadStore } from '@/store/download'
import { getDownload } from '@/apis/account'
import jsonData from './bar.json'
import Styles from './index.module.css'
import { SVGLogo } from '../components/logo'

enum paramStr {
  serve = 'serve', // 服务条款 ID
  privacy = 'privacy', // 隐私策略 ID
  /**  web 显示 */
  isWebOk = 1,
  /** web 不显示 */
  isWebNo = 2,
}

// @ts-ignore
jsonData.assets[0].u = oss_svg_image_domain_address
// @ts-ignore
jsonData.assets[0].p = `default-loading.png`

type footerType = {
  webUrl?: string
  homeColumnName?: string
  isWeb?: string
}

function Footer() {
  const { download, setDownloads, setDisposition, disposition } = useDownloadStore()
  const ref = useRef(null)
  const { setProtocol } = useCardInfo()
  const [visible, setVisible] = useState<boolean>(false) // 弹出层变量
  const { locale } = useCommonStore()
  const pathUrl = getDownloadPath()
  const { clearUserCacheData } = useUserStore()
  const { token } = baseUserStore.getState()
  const [footer, setFooter] = useState<footerType[]>([])
  function pathUrlMethod(e) {
    return `${pathUrl}?type=${e}`
  }
  const appStatusInd = appItemHandle(download)
  const { themeType } = useCommonStore()
  const contactUsData = [
    {
      appStatusInd: appStatusInd.appStoreStatus,
      homeColumnName: 'icon_apple',
      url: pathUrlMethod(equipmentEnum.Apple),
    },
    {
      appStatusInd: appStatusInd.androidStatus,
      homeColumnName: 'icon_android',
      url: pathUrlMethod(equipmentEnum.Android),
    },
    {
      appStatusInd: appStatusInd.windowsStatus,
      homeColumnName: 'icon_windows',
      url: pathUrlMethod(equipmentEnum.Window),
    },
    {
      appStatusInd: appStatusInd.macStatus,
      homeColumnName: 'icon_mac',
      url: pathUrlMethod(equipmentEnum.Mac),
    },
    {
      appStatusInd: appStatusInd.linuxStatus,
      homeColumnName: 'icon_linux',
      url: pathUrlMethod(equipmentEnum.Linux),
    },
  ] // 图标菜单展示
  const onIcon = e => {
    link(e.url)
  }

  /**
   * 退出事件
   * */
  const onSubOut = async () => {
    await clearUserCacheData()
    setVisible(false)
    link('/register/create-account')
  }

  /**
   * 更多设备跳转
   */
  const onMany = () => {
    if (token) {
      setVisible(true)
      return
    }
    onSubOut()
  }

  /** 获取栏目信息 */
  const query = async () => {
    const res = await getSupportColumn({
      businessId,
      lanType: locale,
    })
    const dataList = res?.data?.columnsDatas[0]?.childColumns?.filter(row => row?.isWeb === paramStr.isWebOk)
    setFooter(dataList || [])
    setProtocol(dataList || [])
  }
  // 获取下载列表
  const getList = async () => {
    let param = {
      businessId, // 商户 ID
      lanType: baseCommonStore.getState().locale, // 语言类型
    }
    let res = await getDownload(param)
    if (res.isOk && res?.data) {
      setDownloads(res?.data)
    }
  }
  // 获取网站配置信息
  const getWebsiteDataApi = async () => {
    let param = {
      businessId, // 商户 ID
      lanType: baseCommonStore.getState().locale, // 语言类型
    }
    let res = await getWebsiteData(param)
    if (res.isOk && res?.data) {
      setDisposition(res?.data)
    }
  }
  useEffect(() => {
    query()
    getList()
    // todo 二期功能
    getWebsiteDataApi()
  }, [])

  // useEffect(() => {
  //   // todo 二期功能
  //   setDisposition({ businessName: isCommon() ? 'Van Chain' : 'High VPN' })
  // }, [themeType])

  return (
    <div className={Styles.scoped}>
      <div className="containers">
        <div className="footer-wrap">
          <div className="footer-logo">
            <span>
              <SVGLogo />
            </span>
            <div className="mobile-app-text">Apps</div>
            <div className="hidden lg:block mobile-box">
              <div className="footer-span">
                {footer.map((res, index) => {
                  return (
                    <span
                      key={index}
                      onClick={() => {
                        if (!res?.webUrl) return
                        link(res?.webUrl || '')
                      }}
                    >
                      {res?.homeColumnName || ''}
                    </span>
                  )
                })}
                <span onClick={onMany}>{t`features_layout_header_9kflmgn1qq`}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bootom">
          <div
            className="mt-2 lg:mt-0 cursor-pointer"
            onClick={() => {
              window.open(
                localeIsCnType()
                  ? 'https://docs.google.com/document/d/13YTCyG-O00KsSpckNeYpWCt2XtKHeKtwXYNSJeCPFxc'
                  : 'https://docs.google.com/document/d/1pboBvGHt5lugW2m9MxPJVdCnhlfmXyEmFE9NAwdSTNQ'
              )
            }}
          >
            ©2024 {disposition?.businessName || 'High VPN'}.{t`features_layout_footer_index_q_fnjfqzc5`}
          </div>
          <div className="footer-icon-box">
            {contactUsData.map((res, index) => {
              return (
                <button
                  type="button"
                  disabled={res.appStatusInd === onOFFemum.off}
                  className={classNames(
                    'footer-icon-item',
                    res.appStatusInd === onOFFemum.off && 'footer-icon-item-disabled'
                  )}
                  key={index}
                  onClick={() => {
                    onIcon(res)
                  }}
                >
                  <Icon name={res.homeColumnName} />
                </button>
              )
            })}
          </div>
        </div>
      </div>
      <Modal footer={null} visible={visible} closable={false} className={Styles['herder-modal']}>
        <div className="modal-icon">
          <Icon name="icon_close" onClick={() => setVisible(false)} />
        </div>
        <div>
          <div className="herder-modal-box">
            <div>
              <Icon name="icon_tips" />
            </div>
            <div className="herder-model-item">
              {t`features_layout_header_e7ycyvaotg`} {disposition?.businessName || 'High VPN'}{' '}
              {t`features_layout_header_6e2g0ogole`}
            </div>
          </div>
          <div className="herder-model-button">
            <div className="herder-model-text" onClick={onSubOut}>
              {t`components_subscribe_modal_index_db4_xg16ry`}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default Footer
